import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex w-100 h-100 align-items-center justify-content-center px-1 pt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_skeleton, {
      rows: _ctx.rows,
      animated: ""
    }, null, 8, ["rows"])
  ]))
}