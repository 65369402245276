import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-4" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "col-md-4" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActiveStoresCard = _resolveComponent("ActiveStoresCard")!
  const _component_ActiveSellersCard = _resolveComponent("ActiveSellersCard")!
  const _component_PointsAvailableCard = _resolveComponent("PointsAvailableCard")!
  const _component_ViewsOnSocialCard = _resolveComponent("ViewsOnSocialCard")!
  const _component_TrainingCarriedOutCard = _resolveComponent("TrainingCarriedOutCard")!
  const _component_ActiveStoresChartCard = _resolveComponent("ActiveStoresChartCard")!
  const _component_PointsDistributedChartCard = _resolveComponent("PointsDistributedChartCard")!
  const _component_StoresPodiumCard = _resolveComponent("StoresPodiumCard")!
  const _component_SellersPodiumCard = _resolveComponent("SellersPodiumCard")!
  const _component_ProductsPodiumCard = _resolveComponent("ProductsPodiumCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ActiveStoresCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getActiveStoresCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ActiveSellersCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getActiveSellersCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_PointsAvailableCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getPointsAvailableCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ViewsOnSocialCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getViewsOnSocialCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_TrainingCarriedOutCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getTrainingCarriedOutCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ActiveStoresChartCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getActiveStoresCardChartData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_PointsDistributedChartCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getPointsDistributedCardChartData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_StoresPodiumCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getStoresPodiumCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_SellersPodiumCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getSellersPodiumCardData
        }, null, 8, ["loader", "data"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_ProductsPodiumCard, {
          loader: _ctx.homeStore.getStatusLoader,
          data: _ctx.homeStore.getProductsPodiumCardData
        }, null, 8, ["loader", "data"])
      ])
    ])
  ]))
}