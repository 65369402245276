import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApexCharts = _resolveComponent("ApexCharts")!

  return (_openBlock(), _createBlock(_component_ApexCharts, {
    style: {"margin-left":"-1rem","margin-bottom":"-2.4rem","margin-right":"-1.1rem"},
    type: "area",
    options: _ctx.chartOptions,
    series: _ctx.chartSeries
  }, null, 8, ["options", "series"]))
}