
import axios, { AxiosError } from 'axios'
import { defineComponent, ref, onBeforeMount, watch } from 'vue'
import useAlert from "@/composables/Alert"
import {
  ActiveSellersCard,
  ActiveStoresCard,
  PointsAvailableCard,
  TrainingCarriedOutCard,
  ViewsOnSocialCard,
  ActiveStoresChartCard,
  PointsDistributedChartCard,
  StoresPodiumCard,
  SellersPodiumCard,
  ProductsPodiumCard,
} from "./Partials"
import { IProductPodium, IProductRanking, ISellerPodium, ISellerRanking, IStorePodium, IStoreRanking, TActiveStoresCardChartData, useHomeStore } from '@/store/HomeStore'
import moment from 'moment'
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store'
import { useLoaderStore } from '@/store/LoaderStore'

/** Interfaces: Cards */
export interface IActiveStoresResponse {
  data: {
    error: boolean,
    message: string,
    data: number,
    errorCode: string,
  }
}

export interface IActiveSellersResponse {
  data: {
    error: boolean,
    message: string,
    data: number,
    errorCode: string,
  }
}

export interface IPointsAvailableResponse {
  data: {
    error: boolean,
    message: string,
    data: number,
    errorCode: string,
  }
}

export interface IViewsOnSocialResponse {
  data: {
    error: boolean,
    message: string,
    data: number,
    errorCode: string,
  }
}

export interface ITrainingCarriedOutResponse {
  data: {
    error: boolean,
    message: string,
    data: number,
    errorCode: string,
  }
}

/** Interfaces: Charts */
export interface IActiveStoresChartResponse {
  data: {
    error: boolean,
    message: string,
    data: TActiveStoresCardChartData[],
    errorCode: string,
  }
}

export interface IPointsDistributedChartResponse {
  data: {
    error: boolean,
    message: string,
    data: TActiveStoresCardChartData[],
    errorCode: string,
  }
}

/** Interfaces: Ranking */
export interface IStoresPodiumResponse {
  data: {
    error: boolean,
    message: string,
    data: IStorePodium[],
    errorCode: string,
  }
}

export interface IStoresRankingResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreRanking[],
    errorCode: string,
  }
}

export interface ISellersPodiumResponse {
  data: {
    error: boolean,
    message: string,
    data: ISellerPodium[],
    errorCode: string,
  }
}

export interface ISellersRankingResponse {
  data: {
    error: boolean,
    message: string,
    data: ISellerRanking[],
    errorCode: string,
  }
}

export interface IProductsPodiumResponse {
  data: {
    error: boolean,
    message: string,
    data: IProductPodium[],
    errorCode: string,
  }
}

export interface IProductRankingResponse {
  data: {
    error: boolean,
    message: string,
    data: IProductRanking[],
    errorCode: string,
  }
}

/** Type */
export type THomeViewLocalStorage = {
  cID: number | null,
  start_date: string | null,
  end_date: string | null,
} 

/** Helpers */
export const handlerError = (error: AxiosError | unknown, showTimeAlert: Function) => {
  if(error instanceof AxiosError)
    showTimeAlert(error.response?.data.message, "error")
  else
    showTimeAlert("Algo deu errado!", "error")
}

export const START_OF_YEAR = moment().startOf('year').format('YYYY-MM')
export const CURRENT_MONTH = moment().format('YYYY-MM')

export async function getAllData(
  homeStore: ReturnType<typeof useHomeStore>,
  showTimeAlert: Function,
  id_company: null | number,
  start_date: null | string,
  end_date: null | string,
  month: null | string,
) {
  try {
    homeStore.openLoader()

    const cardBody = {
      id_company,
      month
    }

    const chartBody = {
      id_company,
      start_date,
      end_date,
    }

    const [
      /** Cards */
      activeStoresResponse,
      activeSellersResponse,
      pointsAvailableResponse,
      viewsOnSocialResponse,
      trainingCarriedOutResponse,
      /** Charts */
      activeStoresChartResponse,
      pointsDistributedChartResponse,
      /** Ranking */
      storesPodiumResponse,
      sellersPodiumResponse,
      productsPodiumResponse,
    ] : [
      /** Cards */
      IActiveStoresResponse,
      IActiveSellersResponse,
      IPointsAvailableResponse,
      IViewsOnSocialResponse,
      ITrainingCarriedOutResponse,
      /** Charts */
      IActiveStoresChartResponse,
      IPointsDistributedChartResponse,
      /** Ranking */
      IStoresPodiumResponse,
      ISellersPodiumResponse,
      IProductsPodiumResponse,
    ] = await Promise.all([
      /** Cards */
      axios.post('/api/getReportsStoresActive', cardBody),
      axios.post('/api/getReportsCompetitorsActive', cardBody),
      axios.post('/api/getReportsPointsActive', cardBody),
      axios.post('/api/getReportsPostViews', cardBody),
      axios.post('/api/getReportsTrainingsComplete', cardBody),
      /** Charts */
      axios.post('/api/getReportsStoresActive', chartBody),
      axios.post('/api/getReportsSalesPeriod', chartBody),
      /** Ranking */
      axios.post('/api/getReportsStoresPodium', cardBody),
      axios.post('/api/getReportsSellersPodium', cardBody),
      axios.post('/api/getReportsProductsPodium', cardBody),
    ])
    /** Filter Fields */
    homeStore.setFilterFields({ cID: id_company, start_date, end_date })
    /** Cards */
    homeStore.setActiveStoresCardData(activeStoresResponse.data.data)
    homeStore.setActiveSellersCardData(activeSellersResponse.data.data)
    homeStore.setPointsAvailableCardData(pointsAvailableResponse.data.data)
    homeStore.setViewsOnSocialCardData(viewsOnSocialResponse.data.data)
    homeStore.setTrainingCarriedOutCardData(trainingCarriedOutResponse.data.data)
    /** Charts */
    homeStore.setActiveStoresCardChartData(activeStoresChartResponse.data.data)
    homeStore.setPointsDistributedCardChartData(pointsDistributedChartResponse.data.data)
    /** Ranking */
    homeStore.setStoresPodiumCardData(storesPodiumResponse.data.data)
    homeStore.setSellersPodiumCardData(sellersPodiumResponse.data.data)
    homeStore.setProductsPodiumCardData(productsPodiumResponse.data.data)
  } catch (error) {
    homeStore.setFilterFields(null)
    handlerError(error, showTimeAlert)
  } finally {
    homeStore.closeLoader()
  }
}

export default defineComponent({
  name: "HomeView",
  components: {
    ActiveSellersCard,
    ActiveStoresCard,
    PointsAvailableCard,
    TrainingCarriedOutCard,
    ViewsOnSocialCard,
    ActiveStoresChartCard,
    PointsDistributedChartCard,
    StoresPodiumCard,
    SellersPodiumCard,
    ProductsPodiumCard,
  },
  setup() {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const isLoadingAllData = ref(false)
    const homeStore = useHomeStore()
    const filterBaseV2Store = useFilterBaseV2Store()
    const loaderStore = useLoaderStore()

    /** Functions */
    async function handleGetAllData() {
      loaderStore.open() 
      const company = await filterBaseV2Store.getCompanyInSession()

      if(company?.id === homeStore.getFilterFields?.cID)
        return loaderStore.close()

      try {
        const id_company = company?.id || null
        const start_date = START_OF_YEAR
        const end_date = CURRENT_MONTH
        const month = end_date
        await getAllData(homeStore, showTimeAlert, id_company, start_date, end_date, month)
      } catch (error) {
        console.error(error)
      } finally {
        loaderStore.close()  
      }
    }

    /** Life cycles */
    onBeforeMount(handleGetAllData)

    watch(() => homeStore.getStatusLoader, () => isLoadingAllData.value = homeStore.getStatusLoader)

    return {
      isLoadingAllData,
      homeStore,
    }
  }
})
