import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"color":"var(--cor_texto_1)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartHeaderCard = _resolveComponent("ChartHeaderCard")!
  const _component_ActiveStoresChart = _resolveComponent("ActiveStoresChart")!
  const _component_LoaderCardChart = _resolveComponent("LoaderCardChart")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, { loader: _ctx.loader }, {
    core: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ChartHeaderCard, null, {
          default: _withCtx(() => [
            _createTextVNode(" Lojas Ativas ")
          ]),
          _: 1
        }),
        (Array.isArray(_ctx.chartOptionsCategories) && _ctx.chartOptionsCategories.length && Array.isArray(_ctx.chartSeriesData) && _ctx.chartSeriesData.length)
          ? (_openBlock(), _createBlock(_component_ActiveStoresChart, {
              key: 0,
              chartOptionsCategories: _ctx.chartOptionsCategories,
              chartSeriesData: _ctx.chartSeriesData
            }, null, 8, ["chartOptionsCategories", "chartSeriesData"]))
          : _createCommentVNode("", true)
      ])
    ]),
    loader: _withCtx(() => [
      _createVNode(_component_LoaderCardChart)
    ]),
    _: 1
  }, 8, ["loader"]))
}