
import { defineComponent, watch } from "vue";
import { Modal } from "bootstrap";

export type TStatusModal = {
  open: boolean
}

export default defineComponent({
  name: "BaseModal",
  props: {
    id: {
      type: String,
      required: true
    },
    toggle: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
      emit("get:statusModal", <TStatusModal>{ open: true })
    }

    function closeModal() {
      emit("get:statusModal", <TStatusModal>{ open: false })
    }

    // Life Cycles
    watch(() => props.toggle, () => openModal(props.id))

    return {
      closeModal
    }
  }
})
