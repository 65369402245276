
import { defineComponent, ref } from "vue";
import { BaseModal } from "@/views/Home/Components";
import { TStatusModal } from "@/views/Home/Components/BaseModal.vue";
import { CURRENT_MONTH, handlerError, IProductRankingResponse } from "@/views/Home/HomeView.vue";
import axios from "axios";
import useAlert from "@/composables/Alert";
import { useHomeStore } from "@/store/HomeStore";
import { LoaderStd } from "@/views/Home/Components";
import { useLoaderStore } from "@/store/LoaderStore";
import { CampFooterPaginationControl, CampTable, CampTableTd, CampTableTh } from "@/components";
import { handleFormatInMonentaryValue } from "@/composables/DataValidation";
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store";

export default defineComponent({
  name: "ProductsRankingModal",
  components: {
    BaseModal,
    CampFooterPaginationControl,
    LoaderStd,
    CampTable,
    CampTableTh,
    CampTableTd,
  },
  props: {
    toggle: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Variables
    const { showTimeAlert } = useAlert()
    const homeStore = useHomeStore()
    const isLoading = ref(false)
    const loaderStore = useLoaderStore()
    const filterBaseV2Store = useFilterBaseV2Store()

    // Functions
    async function getDatas() {
      try {
        const company = await filterBaseV2Store.getCompanyInSession()

        const cardBody: {
          id_company: null | number,
          month: null | string,
        } = {
          id_company: company?.id || null,
          month: CURRENT_MONTH,
        }

        const filterFields = homeStore.getFilterFields

        if(filterFields?.cID)
          cardBody.id_company = filterFields.cID

        if(filterFields?.end_date)
          cardBody.month = filterFields.end_date

        const rankingResponse: IProductRankingResponse = await axios.post('/api/getReportsProductsRanking', cardBody)
        homeStore.setProductsRankingCardData(rankingResponse.data.data)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
        loaderStore.close()
      }
    }

    async function handleOpenModal(status: TStatusModal) {
      if(status.open) {
        isLoading.value = true
        await getDatas()
      }
    }

    // Life Cycles


    return {
      isLoading,
      homeStore,
      handleOpenModal,
      handleFormatInMonentaryValue
    }
  }
})
