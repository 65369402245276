
import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { BaseCard, ChartHeaderCard, LoaderStd } from '@/views/Home/Components';
import { ISellerPodium } from '@/store/HomeStore'
import { campRemoveLastNonAlphanumericCharacters, handleFormatInMonentaryValue } from '@/composables/DataValidation';
import userDuotoneImg from '@/assets/views/home/user-duotone.png';
import SellersRankingModal from '@/views/Home/Partials/SellersRankingModal/SellersRankingModal.vue';

const INIT_DATA:ISellerPodium = {
    id: null,
    name: '-',
    total_paid: null,
    url_avatar: null
}

const INIT_DATA_LIST:ISellerPodium[] = [
    { ...INIT_DATA },
    { ...INIT_DATA },
    { ...INIT_DATA },
    { ...INIT_DATA },
    { ...INIT_DATA },
]

export default defineComponent({
    name: "SellersPodiumCard ",
    props: {
        loader: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array as PropType<ISellerPodium[] | null>,
            default: null
        },
    },
    components: {
        BaseCard,
        ChartHeaderCard,
        LoaderStd,
        SellersRankingModal,
    },
    setup(props) {
        /** Variables */
        const listRender = ref([ ...INIT_DATA_LIST ])
        const modalInfoToggle = ref(false)

        /** Functions */
        function handleListRender() {
            if(Array.isArray(props.data) && props.data.length > 0)
                listRender.value = [ ...props.data, ...INIT_DATA_LIST ].slice(0, 5)
            else
                listRender.value = [ ...INIT_DATA_LIST ]
        }

        /** Life Cycles */
        onMounted(() => handleListRender())

        watch(() => props.data, () => handleListRender(), { deep: true })

        return {
            listRender,
            userDuotoneImg,
            modalInfoToggle,
            handleFormatInMonentaryValue,
            campRemoveLastNonAlphanumericCharacters,
        }
    }
})
