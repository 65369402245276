import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6954000"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-column",
  style: {"color":"var(--cor_texto_1)"}
}
const _hoisted_2 = {
  key: 0,
  class: "podium-table"
}
const _hoisted_3 = {
  style: {"width":"5rem"},
  class: "text-start py-2"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "text-start py-2" }
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "text-end py-2" }
const _hoisted_8 = { class: "d-flex align-items-center justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartHeaderCard = _resolveComponent("ChartHeaderCard")!
  const _component_LoaderStd = _resolveComponent("LoaderStd")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_ProductsRankingModal = _resolveComponent("ProductsRankingModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseCard, { loader: _ctx.loader }, {
      core: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ChartHeaderCard, null, {
            default: _withCtx(() => [
              _createTextVNode(" Ranking de Produtos ")
            ]),
            _: 1
          }),
          (Array.isArray(_ctx.listRender))
            ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listRender, (product) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _createElementVNode("td", _hoisted_3, [
                      _createElementVNode("img", {
                        style: {"object-fit":"cover","width":"57px","height":"57px","border-radius":".5rem"},
                        src: product.url_product || _ctx.boxOpenDuotoneImg,
                        alt: product.name
                      }, null, 8, _hoisted_4)
                    ]),
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("span", {
                        title: product.name,
                        style: {"cursor":"default"}
                      }, _toDisplayString(product.name.length <= 20
                                            ? product.name
                                            : _ctx.campRemoveLastNonAlphanumericCharacters(product.name, 20, '...')), 9, _hoisted_6)
                    ]),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.handleFormatInMonentaryValue(product.total_paid ? +product.total_paid : 0)), 1)
                  ]))
                }), 256))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (Array.isArray(_ctx.data) && _ctx.data.length > 0)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalInfoToggle = !_ctx.modalInfoToggle)),
                  class: "btn btn-sm background_btn_1_opct"
                }, " Ver todos "))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      loader: _withCtx(() => [
        _createVNode(_component_LoaderStd, { rows: 6 })
      ]),
      _: 1
    }, 8, ["loader"]),
    _createVNode(_component_ProductsRankingModal, { toggle: _ctx.modalInfoToggle }, null, 8, ["toggle"])
  ]))
}