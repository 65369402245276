
import { defineComponent, PropType, ref } from 'vue';
import ApexCharts from 'vue3-apexcharts';

const COLOR_DEFAULT = '#FFC700'
const COLOR_DARK = '#B38C00'
const COLOR_LIGHT = '#FFD84D'

export default defineComponent({
    name: "PointsDistributedChart",
    props: {
        chartOptionsCategories: {
            type: Array as PropType<string[]>,
            default: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']
        },
        chartSeriesData: {
            type: Array as PropType<number[]>,
            default: [30, 40, 35, 50, 49, 60, 70]
        }
    },
    components: {
        ApexCharts
    },
    setup(props) {
        const chartOptions = ref({
            chart: {
                id: 'points-distributed-chart',
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: [ ...props.chartOptionsCategories ]
            },
            dataLabels: {
                style: {
                    colors: [ COLOR_DARK ],
                    fontSize: '12px'
                }
            },
            stroke: {
                curve: 'smooth',
                colors: [ COLOR_DEFAULT ],
                width: 3
            },
            fill: {
                type: 'gradient',
                colors: [ COLOR_LIGHT ],
                gradient: {
                    shade: 'light',
                    gradientToColors: [ COLOR_DEFAULT ],
                    shadeIntensity: 1,
                    type: 'vertical',
                    opacityFrom: 0.9,
                    opacityTo: 0.1,
                    stops: [0, 100]
                }
            }
        });

        const chartSeries = ref([
            {
                name: 'Total',
                data: [ ...props.chartSeriesData ]
            }
        ]);

        return {
            chartOptions,
            chartSeries,
        }
    }
})
