
import { defineComponent } from 'vue';
import { InfoCard, BaseCard, LoaderCard } from '@/views/Home/Components';

export default defineComponent({
    name: "TrainingCarriedOutCard",
    props: {
        loader: {
            type: Boolean,
            default: false
        },
        data: {
            type: Number,
            default: 0
        },
    },
    components: {
        BaseCard,
        InfoCard,
        LoaderCard,
    },
})
