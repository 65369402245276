import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex w-100 h-100 align-items-center justify-content-between px-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_skeleton, {
      rows: 1,
      class: "w-50",
      animated: ""
    }),
    _createVNode(_component_el_skeleton, {
      style: {"--el-skeleton-circle-size":"72px"},
      class: "d-flex justify-content-end",
      animated: ""
    }, {
      template: _withCtx(() => [
        _createVNode(_component_el_skeleton_item, { variant: "circle" })
      ]),
      _: 1
    })
  ]))
}