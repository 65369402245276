import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column w-100 h-100 align-items-center justify-content-center px-1 pt-1 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_skeleton, { animated: "" }, {
      template: _withCtx(() => [
        _createVNode(_component_el_skeleton_item, { style: {"width":"100%"} })
      ]),
      _: 1
    }),
    _createVNode(_component_el_skeleton, { animated: "" }, {
      template: _withCtx(() => [
        _createVNode(_component_el_skeleton_item, { style: {"width":"100%","height":"25rem"} })
      ]),
      _: 1
    })
  ]))
}