
import { defineComponent } from 'vue';

export default defineComponent({
    name: "BaseCard",
    props: {
        loader: {
            type: Boolean,
            default: false
        }
    },
})
