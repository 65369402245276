
import { defineComponent, PropType, ref, watch, onBeforeMount, onMounted } from 'vue';
import { BaseCard, ChartHeaderCard, LoaderCardChart } from '@/views/Home/Components';
import ActiveStoresChart from './ActiveStoresChart.vue';
import moment from 'moment';
import { TActiveStoresCardChartData } from '@/store/HomeStore'

export default defineComponent({
    name: "ActiveStoresChartCard",
    props: {
        loader: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array as PropType<TActiveStoresCardChartData[] | null>,
            default: null
        },
    },
    components: {
        BaseCard,
        ChartHeaderCard,
        LoaderCardChart,
        ActiveStoresChart,
    },
    setup(props) {
        /** Variables */
        const chartOptionsCategories = ref<string[]>([])
        const chartSeriesData = ref<number[]>([])

        /** Functions */
        function handleData() {
            const data = props.data
            if(!Array.isArray(data))
                return

            chartOptionsCategories.value = data.map(el => moment(el.month, 'YYYY-M').format('MMM YYYY'))
            chartSeriesData.value = data.map(el => el.store_count)
        }

        /** Life Cycles */
        onBeforeMount(() => moment.locale('pt-br'))

        onMounted(handleData)

        watch(() => props.data, () => handleData(), { deep: true })

        return {
            chartOptionsCategories,
            chartSeriesData
        }
    }
})
