
import { defineComponent } from 'vue';

export default defineComponent({
    name: "LoaderStd",
    props: {
        rows: {
            type: Number,
            default: 7
        }
    },
    setup() { }
})
