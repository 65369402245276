import { defineStore } from "pinia";
import { THomeViewLocalStorage } from "@/views/Home/HomeView.vue";

export type TActiveStoresCardChartData = {
  month: string;
  store_count: number;
}

export type TPointsDistributedCardChartData = {
  month: string;
  store_count: number;
}

export interface IStorePodium {
  id: number | null,
  fantasy_name: string,
  total_paid: string | number | null,
}

export interface IStoreRanking extends IStorePodium {
  total_score: string | number | null,
  total_rescue: string | number | null,
}

export interface ISellerPodium {
  id: number | null,
  name: string,
  url_avatar: string | null,
  total_paid: string | number | null,
}
export interface ISellerRanking extends ISellerPodium {
  total_score: string | number | null,
  total_rescue: string | number | null,
}

export interface IProductPodium {
  id: number | null,
  name: string,
  url_product: string | null,
  total_paid: string | number | null,
}
export interface IProductRanking extends IProductPodium {
  total_score: string | number | null,
  total_sales: string | number | null,
}

type THomeData = {
  isLoading: boolean,
  filterFields: THomeViewLocalStorage | null,
  activeStoresCardData: number,
  activeSellersCardData: number,
  pointsAvailableCardData: number,
  trainingCarriedOutCardData: number,
  viewsOnSocialCardData: number,
  activeStoresCardChartData: TActiveStoresCardChartData[] | null,
  pointsDistributedCardChartData: TPointsDistributedCardChartData[] | null,
  storesPodiumCardData: IStorePodium[] | null,
  storesStoreRankingCardData: IStoreRanking[] | null,
  sellersPodiumCardData: ISellerPodium[] | null,
  sellersRankingCardData: ISellerRanking[] | null,
  productsPodiumCardData: IProductPodium[] | null,
  productsRankingCardData: IProductRanking[] | null,
}

export const useHomeStore = defineStore("HomeStore", {
  state: (): THomeData => {
    return {
      isLoading: true,
      filterFields: null,
      activeStoresCardData: 0,
      activeSellersCardData: 0,
      pointsAvailableCardData: 0,
      trainingCarriedOutCardData: 0,
      viewsOnSocialCardData: 0,
      activeStoresCardChartData: null,
      pointsDistributedCardChartData: null,
      storesPodiumCardData: null,
      storesStoreRankingCardData: null,
      sellersPodiumCardData: null,
      sellersRankingCardData: null,
      productsPodiumCardData: null,
      productsRankingCardData: null,
    }
  },
  actions: {
    openLoader() {
      this.isLoading = true
    },
    closeLoader() {
      this.isLoading = false
    },
    setFilterFields(data: THomeViewLocalStorage | null) {
      this.filterFields = data
    },
    setActiveStoresCardData(data = 0) {
      this.activeStoresCardData = data
    },
    setActiveSellersCardData(data = 0) {
      this.activeSellersCardData = data
    },
    setPointsAvailableCardData(data = 0) {
      this.pointsAvailableCardData = data
    },
    setTrainingCarriedOutCardData(data = 0) {
      this.trainingCarriedOutCardData = data
    },
    setViewsOnSocialCardData(data = 0) {
      this.viewsOnSocialCardData = data
    },
    setActiveStoresCardChartData(data: TActiveStoresCardChartData[] | null = null) {
      this.activeStoresCardChartData = data
    },
    setPointsDistributedCardChartData(data: TPointsDistributedCardChartData[] | null = null) {
      this.pointsDistributedCardChartData = data
    },
    setStoresPodiumCardData(data: IStorePodium[] | null = null) {
      this.storesPodiumCardData = data
    },
    setStoresRankingCardData(data: IStoreRanking[] | null = null) {
      this.storesStoreRankingCardData = data
    },
    setSellersPodiumCardData(data: ISellerPodium[] | null = null) {
      this.sellersPodiumCardData = data
    },
    setSellersRankingCardData(data: ISellerRanking[] | null = null) {
      this.sellersRankingCardData = data
    },
    setProductsPodiumCardData(data: IProductPodium[] | null = null) {
      this.productsPodiumCardData = data
    },
    setProductsRankingCardData(data: IProductRanking[] | null = null) {
      this.productsRankingCardData = data
    },
  },
  getters: {
    getStatusLoader():boolean {
      return this.isLoading
    },
    getFilterFields(): THomeViewLocalStorage | null {
      return this.filterFields
    },
    getActiveStoresCardData(): number {
      return this.activeStoresCardData
    },
    getActiveSellersCardData(): number {
      return this.activeSellersCardData
    },
    getPointsAvailableCardData(): number {
      return this.pointsAvailableCardData
    },
    getTrainingCarriedOutCardData(): number {
      return this.trainingCarriedOutCardData
    },
    getViewsOnSocialCardData(): number {
      return this.viewsOnSocialCardData
    },
    getActiveStoresCardChartData(): TActiveStoresCardChartData[] | null {
      return this.activeStoresCardChartData
    },
    getPointsDistributedCardChartData(): TPointsDistributedCardChartData[] | null {
      return this.pointsDistributedCardChartData
    },
    getStoresPodiumCardData(): IStorePodium[] | null {
      return this.storesPodiumCardData
    },
    getStoresStoreRankingCardData(): IStoreRanking[] | null {
      return this.storesStoreRankingCardData
    },
    getSellersPodiumCardData(): ISellerPodium[] | null {
      return this.sellersPodiumCardData
    },
    getSellersRankingCardData(): ISellerRanking[] | null {
      return this.sellersRankingCardData
    },
    getProductsPodiumCardData(): IProductPodium[] | null {
      return this.productsPodiumCardData
    },
    getProductsRankingCardData(): IProductRanking[] | null {
      return this.productsRankingCardData
    },
  }
});
